import React, { useEffect, useState } from 'react';
import { Page } from '../../../lib/Page';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/ApiClient';
import Loading from '../../../lib/Loading';
import { OverviewItem } from '../../../lib/OverviewItem';
import { ViewActionsAndFilters } from '../../../lib/Page/viewFilters';
import debounce from 'lodash/debounce';
import { NewUsersInput } from '../../user-management/containers/new-user-form';
import { Modal } from '../../../lib';
import ResourceGroupCollection from 'domain/ResourceGroup';
import personPlaceholderIcon from 'img/icons/person_placeholder.svg';

import { Session } from '../../../domain/Session';
import { Error } from '../../../lib/PlaceHolder';
import { Tooltip } from '../../../lib/Tooltip';
import { useTranslation } from 'react-i18next';
import { gotoRoute } from '../../route';
import sweetAlert from 'lib/sweetAlert';
import { StatusTable } from './Components/StatusTable';
import { getLearningGroup } from './utils';
import { Formik } from 'formik';

export const LearningGroupDetail = ({ match }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const currentSession = Session.current();
  const canEdit = Session.current()
    .currentPrivileges()
    .hasPrivilege('LearningGroup:edit');

  const learningGroupId =
    match.params.learningGroupId === 'current'
      ? currentSession.currentObjectId()
      : match.params.learningGroupId;

  const queryClient = useQueryClient();

  const [search, setSearch] = useState('');
  const [modal, setModal] = useState();

  const _learningGroup = useQuery(
    ['learningGroups', learningGroupId],
    () => getLearningGroup(learningGroupId),
    {
      placeholderData: {},
    }
  );

  if (!_learningGroup.isFetched) {
    return <Loading type={'fullscreen'} />;
  }
  if (_learningGroup.data?.learningGroupId) {
    const learningGroup = _learningGroup.data;
    const pageProps = {
      title: t('menu:courses'),
      breadcrumbs: [
        { path: '/', title: 'Home' },
        { path: '/development/learningGroups', title: t('menu:courses') },
        { title: learningGroup.name },
      ],
    };

    const filterSearch = (item) => {
      if (search) {
        return (
          (item.firstName + item.lastName + item.email)
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        );
      }
      return true;
    };

    const ManageCourseButtons = () => {
      // Add permission checks
      const [status, setStatus] = useState('ready');
      const provisionCourse = async () => {
        setStatus('loading');
        let { data } = await api.post(
          `learningGroups/${learningGroupId}/provision`
        );
        queryClient.invalidateQueries(['learningGroups']);
      };

      const deleteLearningGroup = async () => {
        sweetAlert({
          title: t('deleteLearningGroupConfirmTitle'),
          text: t('deleteLearningGroupConfirmMessage'),
          dangerMode: true,
          buttons: [t('common:cancel'), t('common:confirm')],
        }).then(async (willDelete) => {
          if (willDelete) {
            await api.delete(`learningGroups/${learningGroupId}`);
            // Don't invalidate. This will cause an additional query to the non-existing item
            // The overview will refetch on its own.
            gotoRoute('development.learningroups');
          }
        });
      };

      const startCourse = async () => {
        setStatus('loading');
        let { data } = await api.post(
          `learningGroups/${learningGroupId}/start`
        );
        queryClient.invalidateQueries(['learningGroups']);
      };

      if (learningGroup.state === 'new' && canEdit) {
        return (
          <>
            <button
              onClick={status === 'ready' ? () => provisionCourse() : () => {}}
              className={'btn btn-primary'}
              disabled={status !== 'ready'}
            >
              {t('provisionCourse')}
              {status === 'loading' && (
                <Loading type={'spinner'} color={'white'} size={16} />
              )}
            </button>
            <button
              onClick={
                status === 'ready' ? () => deleteLearningGroup() : () => {}
              }
              className="btn btn-link"
              disabled={status !== 'ready'}
            >
              {t('common:delete')}
              {status === 'loading' && (
                <Loading type={'spinner'} color={'white'} size={16} />
              )}
            </button>
          </>
        );
      } else {
        if (learningGroup.state === 'provisioned' && canEdit) {
          return (
            <button
              onClick={status === 'ready' ? () => startCourse() : () => {}}
              className={'btn btn-primary'}
              disabled={status !== 'ready'}
            >
              {t('startCourse')}
              {status === 'loading' && (
                <Loading type={'spinner'} color={'white'} size={16} />
              )}
            </button>
          );
        }
        return <div></div>;
      }
    };

    return (
      <Page {...pageProps}>
        <div className="learninggroup-detail">
          <div className="learninggroup-detail-course">
            <div className="row">
              <div className="col-md-6">
                <h5>{learningGroup.name}</h5>
                <p>{t(`courseState.${learningGroup.state}`)}</p>
                <div className="actions">
                  {canEdit && <ManageCourseButtons />}
                </div>
              </div>
              <div className="col-md-6">
                <Teachers
                  learningGroup={learningGroup}
                  setModal={setModal}
                  resourceGroupId={learningGroup.resourceGroupId ?? null}
                />
              </div>
            </div>
          </div>
          {modal}
          <div className="learninggroup-detail-trainees">
            <ViewActionsAndFilters
              filters={[
                {
                  type: 'search',
                  placeholder: t('common:search'),
                  onChange: debounce((v) => setSearch(v), 200),
                },
              ]}
            />
            <div className={'learninggroup-trainees'}>
              <StatusTable
                learningGroup={learningGroup}
                trainees={learningGroup.trainees
                  .filter(filterSearch)
                  .sort((a, b) => {
                    return a.lastName.localeCompare(b.lastName);
                  })}
              />
              <AddTraineeButton
                setModal={setModal}
                learningGroup={learningGroup}
                callback={() => {
                  queryClient.invalidateQueries([
                    'learningGroups',
                    learningGroupId,
                  ]);
                  setModal(null);
                }}
              >
                {t('addTrainee')}
              </AddTraineeButton>
            </div>
          </div>
        </div>
      </Page>
    );
  }
  return (
    <div>
      <Error />
    </div>
  );
};

const Teachers = ({ resourceGroupId, learningGroup, setModal }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const [members, setMembers] = useState([]);
  const getTeachers = async () => {
    const resourceGroup =
      Session.current()._currentResourceGroup.id === resourceGroupId // teacher so current group is learninggroup
        ? Session.current()._currentResourceGroup
        : await ResourceGroupCollection.getOrFetch(resourceGroupId);
    await resourceGroup.members.fetchIfEmpty();
    const members = await resourceGroup.members.toArray();
    return members;
  };

  useEffect(() => {
    (async () => {
      // Teacher in current course
      if (resourceGroupId) {
        setMembers(await getTeachers());
      }
    })();
  }, []);
  if (learningGroup.state !== 'new' && members.length) {
    return (
      <div>
        <h5>{t('teachers')}</h5>
        {members.map((member) => (
          <div
            className={`learningGroup__teacher ${
              member?.invitationId ? 'invited' : ''
            }`}
            key={member.id}
          >
            <img
              src={
                member.has('profilePictureUrl')
                  ? member.profilePictureUrl
                  : personPlaceholderIcon
              }
              alt=""
            />
            <div>
              <div className="teacher-name">
                {`${member.firstName} ${member.lastName}`}
              </div>

              <div className="teacher-mail">{member.email}</div>
            </div>
            {member?.invitationId && (
              <Tooltip
                tooltip={'Docent heeft de uitnodiging nog niet geaccepteerd.'}
              >
                <i className="i-pending i-sm" />{' '}
              </Tooltip>
            )}
            {!member.invitationId && <i className="i-check i-sm" />}
          </div>
        ))}
        <AddTeacherButton
          setModal={setModal}
          learningGroup={learningGroup}
          callback={async () => setMembers(await getTeachers())}
        />
      </div>
    );
  } else {
    return (
      <div>
        <h5>{t('teachers')}</h5>
        <p>{t('teachersEmpty')}</p>
        <AddTeacherButton
          setModal={setModal}
          learningGroup={learningGroup}
          callback={async () => setMembers(await getTeachers())}
        />
      </div>
    );
  }
};

const AddTeacherButton = ({ learningGroup, setModal, callback }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const queryClient = useQueryClient();
  const addTeacher = async () => {
    const resourceGroup =
      Session.current()._currentResourceGroup.id ===
      learningGroup.resourceGroupId
        ? Session.current()._currentResourceGroup
        : await ResourceGroupCollection.getOrFetch(
            learningGroup.resourceGroupId
          );
    const members = await resourceGroup.members;
    setModal(
      <Modal size="lg" onCloseClick={() => setModal()}>
        <NewUsersInput
          overrides={{
            addAnother: t('form.addAnother'),
            addMembers: `${t('form.addTeacher')} ${learningGroup.name}`,
            unnamedMemberLabelKey: `${t('common.role:teacher')}`,
          }}
          roles={[{ roleName: 'teacher' }]} // Visually only
          onAddUsers={(users) => {
            // Add teacher here
            users.forEach(async (user) => {
              await members.create({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                roleNames: ['admin'],
                invitationId: 'created-by-server',
                created: new Date().toISOString(),
              });
            });
            callback();
            queryClient.invalidateQueries(['learningGroup']);
            setModal();
          }}
          resourceGroupName={learningGroup.name}
        />
      </Modal>
    );
  };

  if (learningGroup.state !== 'new' && learningGroup.resourceGroupId) {
    return (
      <button onClick={addTeacher} className="btn btn-primary">
        {t('addTeacher')}
      </button>
    );
  } else {
    return (
      <button disabled className="btn btn-primary">
        {t('addTeacher')}
      </button>
    );
  }
};

const AddTraineeButton = ({ learningGroup, setModal, callback }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const handleSubmit = async (values) => {
    await api.post(`learningGroups/${learningGroup.learningGroupId}/trainees`, {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      tags: {
        refId: values.leagueNr,
      },
    });
    callback();
  };

  const fields = ['email', 'firstName', 'lastName', 'leagueNr'];
  const validate = (values) => {
    let errors = {};
    fields.forEach((field) => {
      if (!values[field]) {
        errors[field] = true;
      }
    });
    return errors;
  };
  const addTrainee = () => {
    setModal(
      <Modal size="md" onCloseClick={() => setModal()}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            ...fields.map((f) => {
              let field = {};
              field[f] = null;
              return field;
            }),
          }}
          validate={validate}
        >
          {({
            handleSubmit,
            values,
            submitCount,
            errors,
            setFieldValue,
            onReset,
            isSubmitting,
          }) => {
            const validClassName = (key) => {
              if (!submitCount) {
                return '';
              }

              return errors[key] ? 'is-invalid' : 'is-valid';
            };
            return (
              <form
                onSubmit={
                  !isSubmitting ? handleSubmit : (e) => e.preventDefault()
                }
              >
                <div className="modal-header">
                  <h5 className="modal-title">{t('addTrainee')}</h5>
                </div>
                <div className="modal-body">
                  {fields.map((f) => {
                    return (
                      <div className={'row'}>
                        <div className="col-12">
                          <div className={`form-group ${validClassName(f)}`}>
                            <label>{t(`traineeForm.${f}`)}</label>
                            <input
                              className={'form-control'}
                              value={values[f]}
                              onChange={(v) => setFieldValue(f, v.target.value)}
                              type={'text'}
                            />
                            <div className="invalid-feedback">
                              {t('common.form:required-field')}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row"></div>
                </div>
                <div className="modal-footer">
                  <div className="form-actions">
                    <button
                      type={isSubmitting ?? 'submit'}
                      className="btn btn-primary"
                    >
                      {t('addTrainee')}{' '}
                      {isSubmitting && (
                        <Loading type={'spinner'} color={'white'} size={16} />
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setModal()}
                    >
                      {t('common:cancel')}
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  if (learningGroup.learningGroupId) {
    return (
      <button onClick={addTrainee} className="btn btn-primary">
        {t('addTrainee')}
      </button>
    );
  } else {
    return (
      <button disabled className="btn btn-primary">
        {t('addTrainee')}
      </button>
    );
  }
};
